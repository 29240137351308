import React, { useCallback } from 'react'
import Particles from "react-tsparticles";
import { loadSlim } from "tsparticles-slim";
import styles from '../App.css'

const ParticlesComponent = () => {
    const particlesInit = useCallback(async engine => {
        console.log(engine);
        // you can initiate the tsParticles instance (engine) here, adding custom shapes or presets
        // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
        // starting from v2 you can add only the features you need reducing the bundle size
        //await loadFull(engine);
        await loadSlim(engine);
      }, []);
      
      const particlesLoaded = useCallback(async container => {
        await console.log(container);
      }, []);
  return (
    <>
      <Particles
      id='tsparticles'
      init={particlesInit}
      loaded={particlesLoaded}
      options={{
        background: {
          color: {
            value: "#fff",
          },
        },
        fpsLimit: 120,
        interactivity: {
          events: {
            onClick: {
              enable: true,
              mode: "push",
            },
            onHover: {
              enable: true,
              mode: "repulse",
            },
          },
          modes: {
            push: {
              quantity: 4,
            },
            repulse: {
              distance: 200,
              duration: 0.4,
            },
          },
        },
        particles: {
          color: {
            value:  [
                "#1E00FF",
                "#FF0061",
                "#E1FF00",
                "#00FF9E"
              ],
          },
          move: {
            direction: "random",
            enable: true,
            outModes: {
              default: "bounce",
            },
            random: false,
            speed: 8,
            straight: false,
          },
          number: {
            density: {
              enable: true,
            },
            value: 40,
          },
          opacity: {
            value: 0.5,
          },
          shape: {
            type: ["image"],
            options: {
                image: [
                    {
                        src: "https://upload.wikimedia.org/wikipedia/commons/thumb/f/f1/Heart_coraz%C3%B3n.svg/640px-Heart_coraz%C3%B3n.svg.png",
                        width: 32,
                        height: 32,
                        particles: {
                            size: {
                                value: 16
                            }
                        }
                    },
                ]
            }
          },
          size: {
            value: { min: 1, max: 5 },
          },
        },
      }}
    />
      </>
  )
}

export default ParticlesComponent