import React, { lazy, Suspense } from 'react';
import SignIn from './components/SignIn/SignIn';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Objects from './components/Objects';
import LandingPage from './components/LandingPage';
import Quotes from './components/Quotes';
import Header from './components/Header';
import { passwordMatches } from './utils/utils';
import MannKiBaat from './components/MannKiBaat';
import { useSelector } from 'react-redux';
import ParticlesComponent from './components/Particles';
import Loader from './components/Loader'
import SongButton from './components/Songs/SongButton';

const Songs = lazy(() => import('./components/Songs/Songs'))
const FallbackComponent = () => {
  <Loader />
}

function App() {
  let userPassword = useSelector((state) => state.user.password)
  const isLoggedIn = passwordMatches(userPassword)

  return (
    <>
      <div style={{ position: 'relative', zIndex: 0, overflowX: 'hidden' }}>
        <ParticlesComponent />
        <Suspense fallback={<FallbackComponent />}>
          <Router>
            {isLoggedIn && <Header />}
            {isLoggedIn && <SongButton />}
            <Routes>
              {isLoggedIn ? (
                <>
                  <Route path="/songs" element={<Songs />} />
                  <Route path="/quotes" element={<Quotes />} />
                  <Route path="/objects" element={<Objects />} />
                  <Route path="/get-started" element={<LandingPage />} />
                  <Route path="/mann-ki-baat" element={<MannKiBaat />} />
                </>
              ) : (
                <Route path="*" element={<SignIn />} /> // Redirect to signin for all other routes
              )}
            </Routes>
          </Router>
        </Suspense>
      </div>
    </>
  );
}

export default App;
