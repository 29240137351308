import * as React from 'react';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Grid } from '@mui/material';
import { cardsData } from '../utils/utils';

const columns = 3;

export default function Quotes() {
  return (
    <>
      <Typography variant="h4" align='center' m={4}>🙂 My Insta Feed Lately 🙂</Typography>
      <Grid container spacing={1} justifyContent="center" alignItems="center">
        {cardsData.map((cardData, index) => (
          <Grid item xs={12} sm={10} md={Math.floor(12 / columns)} key={index}>
            <Card sx={{ maxWidth: 600 }}>
              <CardMedia
                component="img"
                image={cardData.imageUrl}
                alt={cardData.title}
              />
            </Card>
          </Grid>
        ))}
      </Grid>
    </>
  );
}