import React from 'react'
import { Grid, Typography } from '@mui/material'

const MannKiBaat = () => {
  return (
    <Grid container justifyContent='center' alignItems='center' style={{ height: '100vh' }}>
      <Grid item xs={12} sm={12} md={12} px={6}>
        <Typography align='center' variant="h5" component="h1" gutterBottom >
          ••  ••-• If
        </Typography>
        <Typography align='center' variant="h5" component="h1" gutterBottom >
          -• --- - not
        </Typography>
        <Typography align='center' variant="h5" component="h1" gutterBottom >
        -• --- •-- now
        </Typography>
        <Typography align='center' variant="h5" component="h1" gutterBottom >
        -- •- -•-- -••• • maybe
        </Typography>
        <Typography align='center' variant="h5" component="h1" gutterBottom >
        •• -• in
        </Typography>
        <Typography align='center' variant="h5" component="h1" gutterBottom >
        ••-• ••- - ••- •-• • future
        </Typography>
        <Typography align='center' variant="h5" component="h1" gutterBottom >
        ••  ••-• If
        </Typography>
        <Typography align='center' variant="h5" component="h1" gutterBottom >
        -• --- - not
        </Typography>
        <Typography align='center' variant="h5" component="h1" gutterBottom >
        •• -• in
        </Typography>
        <Typography align='center' variant="h5" component="h1" gutterBottom >
        ••-• ••- - ••- •-• • future
        </Typography>
        <Typography align='center' variant="h5" component="h1" gutterBottom >
        -- •- -•-- -••• • maybe
        </Typography>
        <Typography align='center' variant="h5" component="h1" gutterBottom >
        •• -• in
        </Typography>
        <Typography align='center' variant="h5" component="h1" gutterBottom >
        •- -• --- - ••••  • •-• another
        </Typography>
        <Typography align='center' variant="h5" component="h1" gutterBottom >
        •-•• •• ••-• • life
        </Typography>
        <Typography align='center' variant="h5" component="h1" gutterBottom >
        •-•• --- •••- • love
        </Typography>
        <Typography align='center' variant="h5" component="h1" gutterBottom >
        -•-- --- ••- you
        </Typography>
        <Typography align='center' variant="h5" component="h1" gutterBottom >
        •-• •- •--- ••• •••• •-• ••- rajshru
        </Typography>
      </Grid>
    </Grid>
  )
}

export default MannKiBaat