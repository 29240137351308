import { Grid } from '@mui/material';
import React from 'react'
import { Hearts } from 'react-loader-spinner';

const Loader = () => {

    <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: '100vh' }}
    >
        <Grid item>
            <Hearts
                height={200}
                width={200}
                color="#4fa94d"
                ariaLabel="hearts-loading"
                wrapperStyle={{}} // You can add additional styles here if needed
                wrapperClass=""
                visible={true}
            />
        </Grid>
    </Grid>
}

export default Loader