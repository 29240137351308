import React, { useMemo, useState } from "react";

const constructPlayIconShapes = size => {
  return [
    [
      [
        `${100 - size * 100 * Math.cos(Math.PI / 6)}%`,
        `${50 + size * 50 * Math.sin(Math.PI / 6)}%`
      ],
      ["100%", `${50 + size * 50 * Math.sin(Math.PI / 6)}%`],
      ["100%", `${50 - size * 50}%`],
      ["100%", `${50 - size * 50}%`]
    ],
    [
      [
        `${size * 100 * Math.cos(Math.PI / 6)}%`,
        `${50 + size * 50 * Math.sin(Math.PI / 6)}%`
      ],
      ["0%", `${50 + size * 50 * Math.sin(Math.PI / 6)}%`],
      ["0%", `${50 - size * 50}%`],
      ["0%", `${50 - size * 50}%`]
    ]
  ];
};

const constructPauseIconShapes = size => {
  return [
    [
      [
        `${100 - size * 100 * Math.cos(Math.PI / 4)}%`,
        `${50 + size * 50 * Math.sin(Math.PI / 4)}%`
      ],
      [`${100 - size * 20}%`, `${50 + size * 50 * Math.sin(Math.PI / 4)}%`],
      [`${100 - size * 20}%`, `${50 - size * 50 * Math.sin(Math.PI / 4)}%`],
      [
        `${100 - size * 100 * Math.cos(Math.PI / 4)}%`,
        `${50 - size * 50 * Math.sin(Math.PI / 4)}%`
      ]
    ],
    [
      [
        `${size * 100 * Math.cos(Math.PI / 4)}%`,
        `${50 + size * 50 * Math.sin(Math.PI / 4)}%`
      ],
      [`${size * 20}%`, `${50 + size * 50 * Math.sin(Math.PI / 4)}%`],
      [`${size * 20}%`, `${50 - size * 50 * Math.sin(Math.PI / 4)}%`],
      [
        `${size * 100 * Math.cos(Math.PI / 4)}%`,
        `${50 - size * 50 * Math.sin(Math.PI / 4)}%`
      ]
    ]
  ];
};

const PlayPause = ({ playing = false, size = 0.5 }) => {
  const [leftClipPath, rightClipPath] = useMemo(() => {
    const shapes = playing
      ? constructPauseIconShapes(size)
      : constructPlayIconShapes(size);
    return shapes.map(
      vertices =>
        `polygon(${vertices.map(vertex => vertex.join(" ")).join(", ")})`
    );
  }, [playing, size]);

  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        display: "flex",
        transform: `rotate(${playing ? "0deg" : "90deg"})`,
        transition: "transform 500ms ease-in-out"
      }}
    >
      <div
        style={{
          height: "100%",
          width: "50%",
          backgroundColor: "white",
          clipPath: leftClipPath,
          transition: "clip-path 500ms ease-in-out"
        }}
      />
      <div
        style={{
          height: "100%",
          width: "50%",
          backgroundColor: "white",
          clipPath: rightClipPath,
          transition: "clip-path 500ms ease-in-out"
        }}
      />
    </div>
  );
};

const Playpause = () => {
  const [playing, setPlaying] = useState(false);

  return (
    <div
      onClick={() => setPlaying(!playing)}
      style={{
        cursor: "pointer",
        width: 50,
        height: 50,
        backgroundColor: "black",
        borderRadius: 100
      }}
    >
      <PlayPause playing={playing} size={0.5} />
    </div>
  );
};

export default Playpause ;
