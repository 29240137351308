import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  emailID: "",
  password: ""
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    storeEmailID: (state, action) => {
      state.emailID = action.payload;
    },
    storePassword: (state, action) => {
      state.password = action.payload;
    },
    logout: () => {
        return initialState;
    }
  },
});

export const {
  storeEmailID,
  storePassword,
  logout
} = userSlice.actions;
export default userSlice.reducer;
