import React from 'react';
import {
  Typography,
  Grid,
} from '@mui/material'; 

const LandingPage = () => {

  return (
    <Grid container justifyContent='center' alignItems='center' style={{ height: '100vh' }}>
      <Grid item xs={12} sm={6} md={6} px={6}>
        <Typography align='center' variant="h2" component="h1" gutterBottom >
          Welcome, Rajshru
        </Typography>
        <Typography variant="body1" gutterBottom  >
          Thank you for showing interest, I created this small website not to show off, but to express what you mean to me. Things have been tough lately, so I started working on this small project.
        </Typography>
        <Typography variant="body1" gutterBottom  >
          The website has six sections:
        </Typography>
        <ul>
          <li>Home - Currently you're on the homepage (badhiya h na background 😊).</li>
          <li>Songs - In this section, you'll find songs I've been listening to for a while now. They're all a bit sad, but they reflect what I'm going through.</li>
          <li>Quotes - I'm back on Instagram, and this section contains some quotes from my feed that resonate with me.</li>
          <li>Objects - Here, you'll find pictures of things that remind me of you every moment.</li>
          <li>Mann Ki Baat - As the name suggests, this section is for some unsaid things I want to convey to you one last time.</li>
        </ul>
        <Typography variant="body1" gutterBottom  >
        If you want some background music 🎶 then hit the play button at the top left corner. Whenever you refresh the page and hit play button, a new song plays every time. I haven't used any logo, but the text changes on each page you visit 😊.
        </Typography>
        <Typography align='center' variant="h6" gutterBottom  >
          I hope you enjoy exploring the website, Rajshru.
        </Typography>
      </Grid>
    </Grid>  
  );

}

export default LandingPage