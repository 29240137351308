import card1Image from '../data/quotes/1.jpeg';
import card2Image from '../data/quotes/2.jpeg';
import card3Image from '../data/quotes/3.jpeg';
import card5Image from '../data/quotes/5.jpeg';
import card6Image from '../data/quotes/6.jpeg';
import card7Image from '../data/quotes/7.jpeg';
import card8Image from '../data/quotes/8.jpeg';
import card9Image from '../data/quotes/9.jpeg';
import card10Image from '../data/quotes/10.jpeg';
import card11Image from '../data/quotes/11.jpeg';
import card12Image from '../data/quotes/12.jpeg';
import card13Image from '../data/quotes/13.jpeg';
import card14Image from '../data/quotes/14.jpeg';
import card15Image from '../data/quotes/15.jpeg';
import card16Image from '../data/quotes/16.jpeg';
import card17Image from '../data/quotes/17.jpeg';
import card18Image from '../data/quotes/18.jpeg';
import card19Image from '../data/quotes/19.jpeg';
import card20Image from '../data/quotes/20.jpeg';
import card21Image from '../data/quotes/21.jpeg';
import card22Image from '../data/quotes/22.jpeg';
import card23Image from '../data/quotes/23.jpeg';
import card24Image from '../data/quotes/24.jpeg';
import chakli from '../data/objects/chakli.jpeg'
import dead_watch from '../data/objects/dead_watch.jpeg'
import fake_filler from '../data/objects/fake_filler.jpeg'
import jodhpur from '../data/objects/jodhpur.jpeg'
import mailinator from '../data/objects/mailinator.jpeg'
import memento from '../data/objects/memento.jpeg'
import mukhwas from '../data/objects/mukhwas.jpeg'
import phooli from '../data/objects/phooli.jpeg'
import ram_mandir from '../data/objects/ram_mandir.jpeg'
import rj19 from '../data/objects/rj19.jpeg'
import sigma_office from '../data/objects/sigma_office.jpeg'
import silver_pearls from '../data/objects/silver_pearls.jpeg'
import specs_from_your_membership from '../data/objects/specs_from_your_membership.jpeg'
import street_1 from '../data/objects/street_1.jpeg'
import street_2 from '../data/objects/street_2.jpeg'
import street_3 from '../data/objects/street_3.jpeg'
import taj_mahal from '../data/objects/taj_mahal.jpeg'
import my_hands from '../data/objects/my_hands.jpeg'
import my_photo from '../data/objects/my_photo.jpg'
import jajoo_peda from '../data/objects/jajoo_peda.jpeg'
import rajshree_pan_masala from '../data/objects/rajshree_pan_masala.jpeg'
import jupiter from '../data/objects/jupiter.jpeg'
import alto_800 from '../data/objects/alto_800.jpeg'
import song1 from '../data/songs/MITRAZ_-_Saajna__Official_Video_(48k).mp3'
import song2 from '../data/songs/AMAN_-_Akela_Hoon__Official_Lyric_Video_(48k).mp3'
import song3 from '../data/songs/Phir_se_shuru_-_Ashu_Shukla_l_A_song_on_re-initiation_of_love(48k).mp3'
import song4 from '../data/songs/Tu_Chodiyon_Na__Official_Lyrical_Video__-_@RonitVinta___Trending_Song_2023___Big_Bang_Music(48k).mp3'
import song5 from '../data/songs/Shael_-_Hiriye(48k).mp3'
import song6 from '../data/songs/Main_Jahaan_Rahoon__Full_Audio_Song__-_Namastey_London_-_Akshay_Kumar_-_Rahat_Fateh_Ali_Khan(48k).mp3'
import song7 from '../data/songs/Saiyyan_-_Kailash_Kher__Paresh_Kamath__Naresh_Kamath___Jhoomo_Re(48k).mp3'
import song8 from '../data/songs/Anuv_Jain_-_HUSN__Official_Video_(48k).mp3'
import song9 from '../data/songs/Anuv_Jain_-_GUL__Studio_(48k).mp3'
import song10 from '../data/songs/Gajendra_Verma_-_Tune_Mere_Jaana_Kabhi_Nahi_Jaana_I_Emptiness___Gajendra_Verma_Songs___Sonotek_Music(48k).mp3'
import song11 from '../data/songs/Jaane_Ye_Kya_Hua_[Full_Song]_-_Karthik_Calling_Karthik(48k).mp3'
import song12 from '../data/songs/Kaavish_-_Faasle__Studio_Version_(48k).mp3'
import song13 from '../data/songs/Kuch_Is_Tarah_Lyrical_-_Doorie___Atif_Aslam___Mithoon___Atif_Aslam(48k).mp3'
import song14 from '../data/songs/Phir_Suna__Full_Song____Gajendra_Verma___Emptiness___New_Hindi_Song_2020(48k).mp3'
import song15 from '../data/songs/The_Local_Train_-_Aalas_Ka_Pedh_-_Choo_Lo__Official_Audio_(48k).mp3'

export const serviceId = "service_uad541a";
export const templateId = "template_izj5mgn";
export const message = 'Someone signed in !!!';
export const publicKey = 'yqsD6RuVGgWbrHQwU';

export const cardsData = [
  { imageUrl: card1Image },
  { imageUrl: card2Image },
  { imageUrl: card3Image },
  { imageUrl: card10Image },
  { imageUrl: card5Image },
  { imageUrl: card6Image },
  { imageUrl: card7Image },
  { imageUrl: card8Image },
  { imageUrl: card9Image },
  { imageUrl: card11Image },
  { imageUrl: card12Image },
  { imageUrl: card13Image },
  { imageUrl: card14Image },
  { imageUrl: card15Image },
  { imageUrl: card16Image },
  { imageUrl: card17Image },
  { imageUrl: card18Image },
  { imageUrl: card19Image },
  { imageUrl: card20Image },
  { imageUrl: card21Image },
  { imageUrl: card22Image },
  { imageUrl: card23Image },
  { imageUrl: card24Image },
];

export const songsData = [
  { name: "Akela Hu", author: "AMAN", id: "YcYDZ7lJP2w" },
  { name: "Saajna", author: "Mitraz", id: "mYBqt29G9cA" },
  { name: "Main Jahaan Rahoon", author: "Rahat Fateh Ali Khan", id: "dovWTFlRIWs" },
  { name: "Hiriye", author: "Shael", id: "-u99X4_G6Cc" },
  { name: "Tu Chodiyon Na", author: "Ronit Vinta", id: "FcGy7VGURtM" },
  { name: "Faasle", author: "Kavish", id: "ZXtsN7G28Xw" },
  { name: "Saiyyan", author: "Kailash Kher", id: "TuUVVKVdZm4" },
  { name: "Phir Se Shuru", author: "Ashu Shukla", id: "nB7nGcW9TyE" },
  { name: "Phir Suna", author: "Gajendra Verma", id: "2m_iCCUfiz8" },
  { name: "Emptiness", author: "Gajendra Verma", id: "yUu26tcUri0" },
  { name: "Dil Mein Ek Lehar Si Uthi", author: "Ghulam Ali", id: "tfRCEjrhNyw" },
  { name: "Husn", author: "Anuv Jain", id: "gJLVTKhTnog" },
  { name: "Choo Lo", author: "The Local Train", id: "sFMRqxCexDk" },
  { name: "Jaane Ye Kya Hua", author: "KK", id: "HEAmcT5Gy_c" },
  { name: "Gul", author: "Anuv Jain ", id: "SmaY7RfBgas" },
  { name: "Kuch Is Tarah", author: "Atif Aslam", id: "jjk-nmsXYhw" },
  { name: "Apa Fer Milaange", author: "Savi Kahlon", id: "DLZD47lj82o" },
  { name: "Jiyein Kyun", author: "Pritam, Papon", id: "sJW37INWnYk" },
]

export const objectsData = [
  {
    imgPath: chakli,
  },
  {
    imgPath: dead_watch,
  },
  {
    imgPath: fake_filler,
  },
  {
    imgPath: jodhpur,
  },
  {
    imgPath: mailinator,
  },
  {
    imgPath: memento,
  },
  {
    imgPath: mukhwas,
  },
  {
    imgPath: ram_mandir,
  },
  {
    imgPath: rj19,
  },
  {
    imgPath: phooli,
  },
  {
    imgPath: sigma_office,
  },
  {
    imgPath: silver_pearls,
  },
  {
    imgPath: specs_from_your_membership,
  },
  {
    imgPath: jupiter,
  },
  {
    imgPath: street_1,
  },
  {
    imgPath: street_2,
  },
  {
    imgPath: street_3,
  },
  {
    imgPath: alto_800,
  },
  {
    imgPath: jajoo_peda,
  },
  {
    imgPath: rajshree_pan_masala,
  },
  {
    imgPath: taj_mahal,
  },
  {
    imgPath: my_hands,
  },
  {
    imgPath: my_photo,
  },

];

export const songPaths = [
  song1, song2, song3, song4, song5, song6, song7, song8, song9, song10, song11, song12, song13, song14, song15
];

export function getRandomSongPath() {
  const randomIndex = Math.floor(Math.random() * songPaths.length);
  return songPaths[randomIndex];
}

export function base64Encode(data) {
  return btoa(data);
}

export function base64Decode(encodedData) {
  return atob(encodedData);
}

export function passwordMatches(params) {
  return base64Decode(params) === "loveyourajshru"
}

