import { IconButton } from '@mui/material'
import React, { useState } from 'react'
import Playpause from './Playpause'
import useSound from 'use-sound';
import { getRandomSongPath } from '../../utils/utils';

const boopSfx = getRandomSongPath();

const SongButton = () => {
    const [isPlaying, setIsPlaying] = useState(false);
  const [play, {stop} ] = useSound(boopSfx);

  const handleClick = () => {
    if (isPlaying) {
      stop();
    } else {
      play();
    }
    setIsPlaying(!isPlaying);
  };
  return (
    <IconButton
    style={{ position: 'absolute', top: '60px', left: '0px', zIndex: 9999 }}
    color="primary"
    aria-label="play"
    size="large"
    onClick={handleClick}
  >
    <Playpause />
  </IconButton>
  )
}

export default SongButton